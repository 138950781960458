import Vue from 'vue'
import Vuex from 'vuex'
import { Plotly } from 'vue-plotly'
import store from './store'
import axios from 'axios'

import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.component('Plotly', Plotly)
Vue.use(Vuex)
Vue.config.devtools = true

axios.interceptors.request.use(request => {
  store.commit('incrementLoading')
  return request
})
axios.interceptors.response.use(response => {
  store.commit('decrementLoading')
  return response
})

new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
