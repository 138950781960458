<template>
  <v-card>
    <v-toolbar color="indigo" dark>
      <v-toolbar-title>Visualize Home Temperature System.</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="3" align="center" sm> 
          <v-row align="center">
            <v-col cols="12" >
              <date-picker
              @updateDate="getPickedDate"></date-picker>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" @click="callFetchPlot">GET DATA</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="9">
          <plot-graph
            :plotlyData="this.plotlyData"
            @fetchPlotData="callFetchPlot"
          ></plot-graph>
        </v-col>
      </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>

<script>
import DatePicker from "../molecules/DatePicker.vue";
import PlotGraph from "../molecules/PlotGraph";

export default {
  components: { PlotGraph, DatePicker },
  data() {
    return {
      sample: { data: [], layout: {} },
      date: "test"
    };
  },
  computed: {
    plotlyData() {
      return this.$store.state.plotlyData;
    },
  },
  methods: {
    callFetchPlot() {
      this.$store.dispatch("commitGetPlot", {date: this.date});
    },
    getPickedDate(date) {
      console.log(date)
      this.date = date
    },
    testHelloWorld() {
      this.$store.dispatch("commitHelloWorld")
    }
  },
};
</script>
