<template>
  <v-card class="mx-auto">
    <plotly
      :data="plotlyData.data"
      :layout="plotlyData.layout"
      display-mode-bar="true"
    />
    <!--
    <v-btn @click="applyfetchPlotData"> GET </v-btn>
    -->
  </v-card>
</template>

<script>
export default {
  props: ["plotlyData"],
  methods: {
    applyfetchPlotData() {
      this.$emit("fetchPlotData")
    }
  }
}
</script>
