export default {
    incrementLoading(state) {
        state.loading += 1
    },
    decrementLoading(state) {
        state.loading -= 1
    },
    updatePlotData(state, payload) {
        state.plotlyData.data = payload.data
        state.plotlyData.layout = payload.layout
    }
}