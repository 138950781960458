<template>
  <v-row justify="center">
    <v-date-picker v-model="picker" @input="updateDate"></v-date-picker>
  </v-row>
</template>

<script>
export default {
    data() {
        return {
            picker: new Date().toISOString().substr(0, 10)
        }
      },
    methods: {
        updateDate () {
            console.log(this.picker)
            this.$emit('updateDate', this.picker)
        }
    }
}
</script>
