import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

export const getDefaultState = () => {
    return {
        testData: 'test',
        plotlyData: {'data': [], 'layout': {}},
        loading: 0
    }
}
const state = getDefaultState()

export default new Vuex.Store({
    getters,
    actions,
    mutations,
    state
})