<template>
  <v-app>
    <v-main>
      <Loading/>
      <HomeCard/>
    </v-main>
  </v-app>
</template>

<script>
import Loading from './components/molecules/Loading.vue';
import HomeCard from './components/organisms/HomeCard'

export default {
  name: 'App',

  components: {
    HomeCard,
    Loading
  },

  data: () => ({
    //
  }),
};
</script>
