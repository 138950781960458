import axios from "axios";
//axios.defaults.baseURL = 'http://localhost:5050'
axios.defaults.baseURL = 'https://5czuur2q65.execute-api.ap-northeast-1.amazonaws.com/Prod/'

export default {
    async commitGetPlot({ commit }, payload) {
        //const params = new URLSearchParams()
        //params.append('date', payload.date)
        const params = {date: payload.date}

        console.log('send', payload.date)
        const request = await axios.post('/get_temp', params).catch(err => { console.log(err) })
        console.log(request.data)
        commit('updatePlotData', request.data)
    },
    async commitHelloWorld() {
        //const params = new URLSearchParams()
        //params.append('test', 'hoge')
        const request = await axios.post('/hello', {test: "test"}).catch(err => { console.log(err) })
        console.log(request.data)
    }
}